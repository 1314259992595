<template>
  <div class="main-content">
    <div v-if="!isLoadingComponent">
      <div v-for="(notification, key) in notificationActions" :key="key">
        <div class="flex justify-between">
          <p class="text-xl font-roboto flex w-full md:mt-8 mt-5 pr-3 mb-3 font-medium">
            {{ notification.title }}
          </p>
        </div>
        <div v-if="!notification.loading" class="">
          <div v-if="!notification.count">
            <NoRecordFound class="my-2 py-7" />
          </div>
          <div v-else>
            <NotificationTableScheduled
              v-if="notification.scheduled"
              class="bg-primary-white"
              :notification-list="notification.list"
              @update="$emit('update', key, $event)"
            />
            <NotificationTableUnScheduled
              v-else
              :notification-list="notification.list"
              class="bg-primary-white"
              @update="$emit('update', key, $event)"
            />
          </div>
        </div>
        <div v-else class="h-48 pb-36 bg-primary-white p-5 border border-primary-grey rounded-md">
          <Loader :content="true" />
        </div>
        <Pagination
          v-if="notification.count > filteredRecordLimit"
          v-show="!notification.loading"
          :key="`forceRenderPagination${updatePagination}`"
          class="sm:mr-0 -mr-5"
          :record-limit="filteredRecordLimit"
          :max-range="paginationCounts(notification.count, filteredRecordLimit)"
          @filterRecord="$emit('filterRecord', notification, $event)"
        />
      </div>
    </div>
    <div v-else class="h-56 mt-7 pb-36 bg-primary-white p-5 border border-primary-grey rounded-md">
      <Loader :content="true" />
    </div>
  </div>
</template>

<script>
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import NotificationTableUnScheduled from '@/src/components/Settings/notifications/NotificationTableUnScheduled.vue'
import NotificationTableScheduled from '@/src/components/Settings/notifications/NotificationTableScheduled.vue'
/* Utils or mixins */
import GeneralUtil from '@src/mixins/general-mixins.js'
import NoRecordFound from '@components/BaseComponent/NoRecordFound.vue'

export default {
  name: 'NotificationReminderContainer',
  components: {
    Pagination,
    Loader,
    NotificationTableUnScheduled,
    NotificationTableScheduled,
    NoRecordFound,
  },
  mixins: [GeneralUtil],
  props: {
    isLoadingComponent: {
      type: Boolean,
      default: false,
    },
    notificationActions: {
      type: [Array, Object],
      default: () => [],
    },
    filteredRecordLimit: {
      type: Number,
      default: 5,
    },
    updatePagination: {
      type: Number,
      default: 1,
    },
  },
  emits: ['update', 'filterRecord'],
  methods: {},
}
</script>
