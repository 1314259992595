<template>
  <div class="overflow-x-auto rounded-lg border border-primary-grey bg-primary-white">
    <table class="w-full table-auto whitespace-nowrap">
      <thead class="bg-bright-gray">
        <tr class="text-left">
          <th
            v-for="(head, index) in tableHeaders"
            :key="index"
            class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pl-0 rtl:pr-4 ltr:pl-4 ltr:pr-0"
          >
            <span
              class="ltr:border-r rtl:border-l border-primary-grey w-full block rtl:pl-3 ltr:pr-3"
            >
              {{ head }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(notify, index) in notificationList"
          :key="index"
          class="border-primary-grey text-left"
          :class="index !== 0 ? 'border-t' : ''"
        >
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span
              v-if="notify.send_to"
              class="font-roboto font-normal text-sm cursor-pointer capitalize"
            >
              <span class="flex items-center">
                <span v-if="notify.receiver" class="flex">
                  <img
                    v-if="notify.receiver.image"
                    :src="notify.receiver.image"
                    class="w-9 h-9 bg-gray-300 rounded-full mr-3"
                  />
                  <div
                    v-else
                    class="w-9 h-9 mr-3 rounded-full bg-gray-300 flex justify-center items-center text-xxs text-gray-700"
                  >
                    {{
                      getInitialsFromFirstAndLastName(
                        notify.receiver.first_name,
                        notify.receiver.last_name,
                      )
                    }}
                  </div>
                  <span class="mt-2 mr-1">{{ notify.receiver.full_name }}</span>
                </span>
                <span v-else>
                  {{ notify.send_to.replaceAll('_', ' ') }}
                </span>
              </span>
            </span>
            <span v-else>{{ GENERAL_CONSTANTS.NOT_APPLICABLE }}</span>
          </td>
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span class="font-roboto font-normal text-sm cursor-pointer capitalize">
              {{ notify.userRole || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </td>
          <td class="ltr:border-r rtl:border-l border-primary-grey w-24 rtl:text-right px-3 py-4">
            <span
              v-if="notify.mediums && notify.mediums.length"
              class="font-roboto font-normal text-1.5xs flex rounded-md items-center gap-1.5"
              :class="notify.mediums.length !== 1 ? 'bg-light-grey px-2 py-1' : ''"
            >
              <span
                v-for="(medium, i) in notify.mediums"
                :key="i"
                :class="notify.mediums.length === 1 ? 'px-2 py-1 bg-light-grey rounded-md' : ''"
                class="flex capitalize gap-1.5"
              >
                {{ medium }}
                <Icon
                  v-if="i + 1 !== notify.mediums.length"
                  class="icon opacity-50 lg:opacity-100 cursor-pointer"
                  color="primary-grey-light"
                  icon="chevronRight"
                  height="20"
                  width="20"
                />
              </span>
            </span>
            <span v-else class="font-roboto font-normal text-1.5xs flex rounded-md">
              {{ GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </td>
          <td class="ltr:border-r rtl:border-l border-primary-grey w-24 rtl:text-right px-3 py-4">
            <span
              v-if="notify.mediums && notify.mediums.length"
              class="font-roboto font-normal text-1.5xs flex rounded-md"
            >
              {{
                notify.send_all_mediums
                  ? 'Send to all mediums selected'
                  : 'Send to first medium found'
              }}
            </span>
            <span v-else class="font-roboto font-normal text-1.5xs flex rounded-md">
              {{ GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </td>
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span
              class="font-roboto font-normal bg-gray-100 text-sm cursor-pointer px-3 py-1.5 rounded"
            >
              {{
                notify.in_app_notification_enabled
                  ? GENERAL_CONSTANTS.ENABLED
                  : GENERAL_CONSTANTS.DISABLED
              }}
            </span>
          </td>
          <td class="border-primary-grey w-10 px-5">
            <TableAction
              :idx="index"
              :current-user="notify"
              :action-list="getActionList(notify)"
              :right="50"
              :rtl-right="-245"
              @action="typeAction"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Icon from '@components/icons/icon.vue'
import TableAction from '@components/TableAction.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import { getInitialsFromFirstAndLastName } from '@utils/generalUtil'

export default {
  name: 'NotificationTaleType1',
  components: {
    TableAction,
    Icon,
  },
  props: {
    notificationList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update'],
  data() {
    return {
      GENERAL_CONSTANTS,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      tableHeaders: [
        'Reminder To',
        'Receiver Role',
        'Mediums',
        'External Medium Settings',
        'In-App Notification',
        'Action',
      ],
    }
  },
  methods: {
    getInitialsFromFirstAndLastName,
    getActionList(notification) {
      let actionList = [{ name: 'Edit' }]
      if (notification.is_upper_level) actionList.push({ name: 'Disable' })
      else actionList.push({ name: 'Delete' })
      if (notification.disabled_at) {
        actionList = [{ name: 'Enable' }]
      }
      return actionList
    },
    typeAction(action, currentAction, idx) {
      this.$emit('update', { performedAction: action, ...currentAction })
    },
  },
}
</script>

<style scoped>
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
