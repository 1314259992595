<template>
  <div class="overflow-x-auto">
    <table class="w-full table-auto border-b mb-0.5 border-primary-grey whitespace-nowrap">
      <tbody>
        <tr
          v-for="(notify, index) in notificationList"
          :key="index"
          class="border-t border-primary-grey text-left"
        >
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span class="font-roboto font-normal text-sm">Send a reminder to</span>
          </td>
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span v-if="notify.send_to" class="font-roboto font-normal text-sm capitalize">
              <span class="text-primary-green">
                {{ notify.send_to.replaceAll('_', ' ') }}
                <span v-if="notify.isConcerned" class="text-xs lowercase">
                  ({{ notify.isConcerned }})
                </span>
              </span>
            </span>
            <span v-else>{{ GENERAL_CONSTANTS.NOT_APPLICABLE }}</span>
          </td>
          <td
            v-if="notify.is_before"
            class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4"
          >
            <span class="font-roboto font-normal text-sm">
              {{ notify.is_before ? 'Before' : 'After' }}
            </span>
          </td>
          <td
            v-if="notify.before_after_quantity"
            class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4"
          >
            <span class="font-roboto font-normal text-sm">{{ notify.before_after_quantity }}</span>
            <span class="text-primary-green px-1">Days</span>
          </td>
          <td
            v-if="notify.notify_at"
            class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4"
          >
            <span class="font-roboto font-normal text-sm">
              at
              <span class="text-primary-green">{{ $filters.dateFormat(notify.notify_at) }}</span>
            </span>
          </td>
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span v-if="notify.mediums" class="font-roboto font-normal text-sm flex">
              <span
                v-for="(medium, i) in notify.mediums"
                :key="i"
                class="text-primary-green flex capitalize"
              >
                {{ medium }}
                <Icon
                  v-if="i + 1 !== notify.mediums.length"
                  class="icon opacity-50 lg:opacity-100 mt-0.5 mx-2"
                  color="primary-green"
                  icon="arrowRight"
                  height="15"
                  width="15"
                />
              </span>
            </span>
            <span v-else>{{ GENERAL_CONSTANTS.NOT_APPLICABLE }}</span>
          </td>
          <td class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4">
            <span class="font-roboto font-normal text-sm flex">
              In app notification :
              <span class="text-primary-green ml-1">
                {{ notify.in_app_notification_enabled ? 'Enabled' : 'Disabled' }}
              </span>
            </span>
          </td>
          <td class="border-primary-grey w-10 px-5">
            <TableAction
              :idx="index"
              :current-user="notify"
              :action-list="actionList"
              :right="50"
              :rtl-right="-245"
              @action="typeAction"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TableAction from '@components/TableAction.vue'
import Icon from '@components/icons/icon.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export default {
  name: 'NotificationTaleType1',
  components: {
    TableAction,
    Icon,
  },
  props: {
    notificationList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update'],
  data() {
    return {
      GENERAL_CONSTANTS,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
    }
  },
  methods: {
    typeAction(action, currentAction, idx) {
      this.$emit('update', { performedAction: action, ...currentAction })
    },
  },
}
</script>
