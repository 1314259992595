import { transformTextToCapitalize } from '@utils/generalUtil'
import { NOTIFCATIONS_OPTIONS } from '@src/constants/notification-constants.js'

export const transformNotification = (notification) => {
  if (notification.receiver) {
    notification.receiver.full_name = `${notification.receiver.first_name} ${notification.receiver.last_name}`
  }
  notification.mediums = []
  if (notification.first_priority_medium) {
    notification.mediums.push(notification.first_priority_medium)
  }
  if (notification.second_priority_medium) {
    notification.mediums.push(notification.second_priority_medium)
  }
  if (notification.receiver_role_id)
    notification.userRole = transformTextToCapitalize(notification.receiver_role_id)
  else notification.userRole = transformTextToCapitalize(notification.send_to)
  return notification
}

export const getNotificationsOptionsList = (options) => {
  return NOTIFCATIONS_OPTIONS.filter((option) => options.includes(option.value))
}
