import { mapActions, mapState } from 'vuex'
import { transformNotification } from '@utils/notifications'
import GENERAL_CONSTANT from '@src/constants/general-constants'
export default {
  data() {
    return {
      notificationActions: {},
      notifications: 'notifications',
      filteredRecordLimit: GENERAL_CONSTANT.MODAL_RECORD_LIMIT,
      unScheduleModel: false,
      scheduleModel: false,
      currentActionObject: null,
      currentNotificationObject: null,
      isLoadingComponent: false,
      updatePagination: 0,
      filtersPayload: {},
    }
  },
  computed: {
    ...mapState('layout', ['currentCampusScope', 'currentSectionScope', 'currentClassScope']),
  },
  watch: {
    currentSectionScope: {
      handler() {
        this.getAllNotificationsList()
      },
      deep: true,
    },
    currentCampusScope: {
      handler() {
        this.getAllNotificationsList()
      },
      deep: true,
    },
    currentClassScope: {
      handler() {
        this.getAllNotificationsList()
      },
    },
  },
  created() {
    this.setActionList()
    this.setRightBar()
    this.setConcernedAndOptionsList()
    this.getAllNotificationsList()
  },
  methods: {
    updateNotificationsList(...rest) {
      if (this.filtersPayload.disabled) {
        this.filtersPayload.disabled = false
        this.getAllNotificationsList()
      } else {
        this.filterRecord(...rest)
      }
    },

    typeAction(key, event) {
      switch (event.performedAction) {
        case 'Edit':
          this.currentActionObject = this.notificationActions[key]
          this.currentNotificationObject = event
          if (this.currentActionObject.scheduled) this.toogleScheduleModel()
          else this.toggleUnScheduleModel()
          break
        case 'Delete':
          this.deleteNotificationTriggerById(key, event)
          break
        case 'Disable':
          this.disableNotificationTriggerById(key, event)
          break
        case 'Enable':
          this.enableNotificationTriggerById(key, event)
          break
      }
    },
    async deleteNotificationTriggerById(key, event) {
      this.notificationActions[key].loading = true
      this.dataPayload.payload = event
      const [res, err] = await this.deleteNotificationTrigger(this.dataPayload)
      this.filterRecord(this.notificationActions[key])
      this.updatePagination++
    },

    async disableNotificationTriggerById(key, event) {
      this.notificationActions[key].loading = true
      this.dataPayload.payload = event
      const [res, err] = await this.disableNotificationTrigger(this.dataPayload)
      this.filterRecord(this.notificationActions[key])
    },

    async enableNotificationTriggerById(key, event) {
      this.notificationActions[key].loading = true
      this.dataPayload.payload = event
      const [res, err] = await this.enableNotificationTrigger(this.dataPayload)
      this.getAllNotificationsList()
      this.updatePagination++
    },
    async getAllNotificationsList(data) {
      if (data) {
        this.filtersPayload = data
      }
      this.setActionList()
      this.setConcernedAndOptionsList()
      this.isLoadingComponent = true
      this.dataPayload.payload = this.filtersPayload
      const [res, err] = await this.getNotificationTriggers(this.dataPayload)
      this.isLoadingComponent = false
      if (res) {
        Object.values(this.notificationActions).forEach((notification) => {
          if (res.data[notification.action]) {
            notification.list = res.data[notification.action].records.map(transformNotification)
            notification.count = res.data[notification.action].meta.total_records
          }
        })
      }
    },
    async filterRecord(
      filterObj,
      pagination = {
        skip: GENERAL_CONSTANT.RECORD_SKIP,
        limit: GENERAL_CONSTANT.MODAL_RECORD_LIMIT,
      },
      updatePagination = false,
    ) {
      const data = { ...filterObj, ...pagination, ...this.filtersPayload }
      if (updatePagination) {
        this.updatePagination++
      }
      delete data['list']
      delete data['optionsList']
      filterObj.loading = true
      this.dataPayload.payload = data
      const [res, err] = await this.getNotificationTriggers(this.dataPayload)
      /* HANDLING SINGLE ACTION EVERY TIME */
      filterObj.list = res.data[filterObj.action].records.map(transformNotification)
      filterObj.count = res.data[filterObj.action].meta.total_records
      filterObj.loading = false
    },

    clearForm() {
      this.currentActionObject = null
      this.currentNotificationObject = null
    },

    toggleUnScheduleModel() {
      this.unScheduleModel = !this.unScheduleModel
      if (!this.unScheduleModel) {
        this.clearForm()
      }
    },
    toogleScheduleModel() {
      this.scheduleModel = !this.scheduleModel
      if (!this.scheduleModel) {
        this.clearForm()
      }
    },
    async addNotification(data) {
      let res, err
      if (this.currentNotificationObject) {
        data.id = this.currentNotificationObject.id
        this.dataPayload.payload = data
        ;[res, err] = await this.updateNotificationTrigger(this.dataPayload)
      } else {
        this.dataPayload.payload = data
        ;[res, err] = await this.createNotificationTrigger(this.dataPayload)
      }
      if (res) {
        this.updateNotificationsList(this.currentActionObject)
        this.toggleUnScheduleModel()
      }
    },

    ...mapActions('notifications', [
      'getNotificationTriggers',
      'createNotificationTrigger',
      'updateNotificationTrigger',
      'deleteNotificationTrigger',
      'disableNotificationTrigger',
      'enableNotificationTrigger',
    ]),
  },
}
